<template>
  <div
    class="main-header"
    :class="{
      'main-header--logo-position-left': logoPositionLeft,
    }"
  >
    <div class="main-header__top container">
      <mobile-menu
        ref="mobileMenuRef"
        :fields="fields"
        :booking-options="bookingOptions"
        @mobile-menu-open="headerCTARef?.closeBookingMenu()"
      />
      <div
        class="main-header__top-buttons"
        :class="{ ...detectComponentVariant(variantedComponents.MAIN_HEADER) }"
      >
        <div class="main-header__logo">
          <router-link
            :to="fields?.link?.value?.href"
            data-test-id="logo"
            class="main-header__link no-underline"
          >
            <img
              :src="fields?.logo?.value?.src"
              :width="fields.logo?.value?.width"
              :height="fields.logo?.value?.height"
              :alt="fields?.logo?.value?.alt"
              :title="fields?.link?.value?.text"
              fetchpriority="high"
            />
          </router-link>
        </div>
        <header-cta
          v-if="!logoPositionLeft || !isDesktop"
          :ref="(el) => setHeaderCtaRef(el, false)"
          v-bind="headerCTABinds"
        />
      </div>
    </div>
    <desktop-menu
      ref="desktopMenuRef"
      :menus="fields?.navItems"
      :logo-position="fields?.logoPosition?.value"
      @mega-nav-open="onMegaNavOpen"
      @mega-nav-close="onMegaNavClose"
    >
      <header-cta
        v-if="logoPositionLeft"
        :ref="(el) => setHeaderCtaRef(el, true)"
        v-bind="headerCTABinds"
      />
    </desktop-menu>
  </div>
</template>

<script setup>
import { computed, ref, provide, inject } from 'vue';
import { RouterLink } from 'vue-router';
import DesktopMenu from './DesktopMenu/DesktopMenu.vue';
import useMedia from '../../utils/useMedia';
import { mainHeaderBookingDataLayer } from '../../data-layer/mainHeader';
import { useRegisterDataLayerAction } from '../../data-layer/helpers/registerDataLayer';
import {
  useDetectVariantByBrandAndTheme,
  variantedComponents,
} from '../../utils/componentVariants';
import MobileMenu from './MobileMenu/MobileMenu.vue';
import HeaderCta from './HeaderCta.vue';

const CTA_BOOK_TABLE = 'ctaBookTable';
const CTA_BOOK_ROOM = 'ctaBookRoom';

const jssStore = inject('jssStore');
const context = jssStore?.sitecoreContext() || {};
const guestlineUrl = context.guestlineUrl;
const venueID = context.venueId;

const detectComponentVariant = useDetectVariantByBrandAndTheme();
const { isMedia: isDesktop } = useMedia('(min-width: 1024px)');

const props = defineProps({
  fields: {
    type: Object,
    default: () => ({}),
  },
  rendering: {
    type: Object,
  },
  params: {
    type: Object,
  },
});

provide('navLinkImagePlaceholder', props.fields.navLinkImagePlaceholder?.value);

const bookingOptionsData = {
  [CTA_BOOK_TABLE]: {
    key: 'book-table-cta',
    labelLong: 'book-table-cta-long',
    labelShort: 'book-cta-text-short',
    action: mainHeaderBookingDataLayer.actions.BOOK_A_TABLE,
    icon: 'icon-Cutlry2',
    linktype: 'internal',
  },
  [CTA_BOOK_ROOM]: {
    key: 'book-room-cta',
    labelLong: 'book-room-cta-long',
    labelShort: 'book-cta-text-short',
    action: mainHeaderBookingDataLayer.actions.BOOK_A_ROOM,
    icon: 'icon-Bed',
  },
};

const bookingOptions = computed(() => setBookingOptions());
const logoPositionLeft = computed(() => props.fields?.logoPosition?.value == 'left');
const headerCTABinds = computed(() => ({
  ctaHeaderLink: props.fields?.ctaHeaderLink,
  ctaButtonTabIndex: ctaButtonTabIndex.value,
  bookingOptions: bookingOptions.value,
  onBookingMenuOpen: () => desktopMenuRef.value?.closeMegaNav(),
}));

const mobileMenuRef = ref(null);
const desktopMenuRef = ref(null);
const headerCTARef = ref(null);
const ctaButtonTabIndex = ref(null);

const setHeaderCtaRef = (el, isInDesktopMenu) => {
  if (!el) return;
  if ((isInDesktopMenu && isDesktop.value) || !isInDesktopMenu) headerCTARef.value = el;
};

const onMegaNavOpen = () => {
  headerCTARef.value?.closeBookingMenu();
  ctaButtonTabIndex.value = -1;
};

const onMegaNavClose = () => {
  ctaButtonTabIndex.value = null;
};

const pushBookTable = () => {
  if (!props.fields?.[CTA_BOOK_TABLE]?.value?.href) return;

  bookingOptionsData[CTA_BOOK_TABLE].data = props.fields[CTA_BOOK_TABLE].value;

  if (!bookingOptionsData[CTA_BOOK_TABLE].data.linktype)
    bookingOptionsData[CTA_BOOK_TABLE].data.linktype = bookingOptionsData[CTA_BOOK_TABLE].linktype;

  return bookingOptionsData[CTA_BOOK_TABLE];
};

const pushBookRoom = () => {
  if (!(guestlineUrl && venueID)) return;

  bookingOptionsData[CTA_BOOK_ROOM].guestlineUrl = guestlineUrl;

  return bookingOptionsData[CTA_BOOK_ROOM];
};

const setBookingOptions = () => {
  const options = {};

  const ctaBookTable = pushBookTable();
  if (ctaBookTable) options[CTA_BOOK_TABLE] = ctaBookTable;

  const ctaBookRoom = pushBookRoom();
  if (ctaBookRoom) options[CTA_BOOK_ROOM] = ctaBookRoom;

  return options;
};

const registerDataLayerAction = useRegisterDataLayerAction();

const handleBookingClick = (action) => {
  mobileMenuRef.value?.closeMobileMenu();
  desktopMenuRef.value?.closeMegaNav();

  registerDataLayerAction({
    event_name: 'click_button',
    link_text: action,
    intent: action,
    component: 'main_header',
  });
};

const handleNavigationItemClick = (action, component) => {
  mobileMenuRef.value?.closeMobileMenu();
  desktopMenuRef.value?.closeMegaNav();

  registerDataLayerAction({
    event_name: component === 'main_header_promo' ? 'click_button' : 'click_link',
    link_text: action,
    intent: 'navigation_interaction',
    component,
  });
};

provide('dataLayerFunctions', {
  handleBookingClick,
  handleNavigationItemClick,
});
</script>

<style lang="scss">
@import './scss/MainHeader.scss';
</style>
