import { onMounted, onBeforeUnmount } from 'vue';
import { useAppStore } from '../stores/appStore';

export function useGuestlineListener(guestlineUrl) {
  const appStore = useAppStore();

  const generateGuestlineUrl = (el) => {
    const url = new URL(guestlineUrl);
    Object.keys(el.dataset).forEach((key) => {
      if (key === 'promocode') url.searchParams.append('promoCode', el.dataset[key]);
      else if (key !== 'guestline') url.searchParams.append(key, el.dataset[key]);
    });
    appStore.setGuestlineActiveUrl(url.toString());
  };

  const onBodyClick = (e) => {
    if (e.target.closest('[data-guestline]')) {
      generateGuestlineUrl(e.target.closest('[data-guestline]'));
    }
  };

  onMounted(() => {
    document.addEventListener('click', onBodyClick);
  });

  onBeforeUnmount(() => {
    document.removeEventListener('click', onBodyClick);
  });
}
