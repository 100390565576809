import { onMounted, onBeforeUnmount, ref, watchEffect, nextTick, computed } from 'vue';
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap';
import { useAppStore } from '../stores/appStore';

export const usePopupModal = ({ popupID, modalRef }) => {
  const appStore = useAppStore();

  const isModalOpen = ref(false);
  const isDialogElement = computed(() => modalRef.value?.nodeName === 'DIALOG');

  const { activate, deactivate } = useFocusTrap(modalRef, {
    allowOutsideClick: true,
    escapeDeactivates: false,
  });

  const closeModal = () => {
    deactivate();
    nextTick(() => {
      isDialogElement.value && modalRef.value.close();
      appStore.unblockScrolling();
      isModalOpen.value = false;
    });
  };

  const openModal = () => {
    isModalOpen.value = true;
    appStore.blockScrolling();
    nextTick(() => {
      isDialogElement.value && modalRef.value.showModal();
      activate();
    });
  };

  const onLinkClick = (event) => {
    const link = event.target.closest(`a[href="#${popupID}"]`);
    if (link) {
      event.preventDefault();
      openModal();
    }
  };

  watchEffect(() => {
    if (!isDialogElement.value) {
      nextTick(() => {
        appStore.setBackdrop({ enable: isModalOpen.value, global: true });
      });
    }
  });

  onMounted(() => {
    if (popupID) document.addEventListener('click', onLinkClick);
  });

  onBeforeUnmount(() => {
    if (popupID) document.removeEventListener('click', onLinkClick);
    !isDialogElement.value && appStore.setBackdrop({ enable: false, global: true });
  });

  return {
    isModalOpen,
    closeModal,
    openModal,
  };
};
