<template>
  <nav class="desktop-menu">
    <div
      class="desktop-menu__top container"
      :class="{ ...detectComponentVariant(variantedComponents.MAIN_HEADER) }"
    >
      <ul class="desktop-menu__menu-list">
        <li v-for="(menuItem, index) in menus" :key="index">
          <button
            v-if="menuItem?.fields?.columns?.length > 0"
            ref="menusRefs"
            class="desktop-menu__nav-item desktop-menu__nav-item--has-subitems"
            :class="{
              'desktop-menu__nav-item--selected': selectedMenu === index && !isLinkHovered,
              ...navItemClasses,
            }"
            :tabindex="isMenuSelected && selectedMenu !== index ? -1 : 0"
            :aria-expanded="selectedMenu === index"
            :data-test-id="`nav-item-${index}`"
            @mouseover="(e) => toggleMegaNav(index, e)"
            @mouseleave="closeMegaNavWithTimeout"
            @click="() => toggleMegaNav(index)"
            @keydown.enter.space.prevent="() => toggleMegaNav(index)"
            @keydown.tab.shift.exact="closeMegaNav"
          >
            <span>{{ menuItem?.fields?.title?.value }}</span>
            <i class="icon icon-Down" aria-hidden="true"></i>
          </button>
          <component
            :is="getLinkTag(menuItem?.fields?.link?.value?.linktype)"
            v-else
            v-bind="getLinkBindings(menuItem?.fields?.link)"
            ref="menusRefs"
            class="desktop-menu__nav-item desktop-menu__nav-item--link no-underline"
            :class="{
              'desktop-menu__nav-item--selected':
                `/${route?.params?.sitecoreRoute?.[0] ?? ''}` ===
                  menuItem?.fields?.link?.value?.href &&
                !isMenuSelected &&
                !isLinkHovered,
              ...navItemClasses,
            }"
            :tabindex="isMenuSelected ? -1 : 0"
            :data-test-id="`nav-item-${index}`"
            @mouseover="isLinkHovered = true"
            @mouseleave="isLinkHovered = false"
            @focus="closeMegaNavWithTimeout"
            @click="
              handleNavigationItemClick(menuItem?.fields?.title?.value, 'main_header_top_level')
            "
          >
            {{ menuItem?.fields?.title?.value }}
          </component>
        </li>
      </ul>
      <slot></slot>
    </div>
    <div
      class="desktop-menu__mega-nav"
      :class="{
        'desktop-menu__mega-nav--visible': isMenuSelected,
      }"
      @mouseover="() => openMegaNav(selectedMenu)"
      @mouseleave="closeMegaNavWithTimeout"
    >
      <transition-group
        name="mega-nav-transition"
        mode="out-in"
        tag="div"
        class="desktop-menu__wrapper"
      >
        <template v-for="(menu, ulIndex) in computedMenus">
          <ul
            v-if="menu.fields.columns.length > 0"
            v-show="ulIndex === selectedMenu || isServer()"
            ref="megaNavContainerRef"
            :key="ulIndex"
            :data-index="ulIndex"
            class="desktop-menu__container container"
            :class="menu.classes"
            aria-label="Navigation columns"
          >
            <li
              v-for="(item, liIndex) in menu.fields.columns"
              :key="liIndex"
              class="desktop-menu__column"
            >
              <mega-nav-column
                v-bind="item.fields"
                :nav-items-type="menu.navItemsType"
                :column-index="liIndex"
                :num-of-columns="menu.numOfColumns"
                @keydown.tab.exact="onLastFocusableItemTab"
              />
            </li>
            <li v-if="menu.isFeaturedItem" class="desktop-menu__featured-item">
              <featured-item
                :fields="menu.fields"
                :num-of-columns="menu.numOfColumns"
                class="var-dark"
                @keydown.tab.exact="onLastFocusableItemTab"
              />
            </li>
          </ul>
        </template>
      </transition-group>
    </div>
  </nav>
</template>

<script setup>
import { ref, onBeforeUnmount, computed, watch, watchEffect, inject } from 'vue';
import { isServer } from '@sitecore-jss/sitecore-jss';
import { useRoute } from 'vue-router';
import { useScroll } from '@vueuse/core';
import { getLinkTag, getLinkBindings } from '../../../utils/linkTransformer';
import {
  useDetectVariantByBrandAndTheme,
  variantedComponents,
} from '../../../utils/componentVariants';
import MegaNavColumn from './MegaNavColumn/MegaNavColumn.vue';
import FeaturedItem from '../FeaturedItem/FeaturedItem.vue';
import { useAppStore } from '../../../stores/appStore';

const detectComponentVariant = useDetectVariantByBrandAndTheme();
const appStore = useAppStore();
const route = useRoute();

const { handleNavigationItemClick } = inject('dataLayerFunctions');
const jssStore = inject('jssStore');
const brandName = jssStore?.sitecoreContext()?.brandName;

const emit = defineEmits(['mega-nav-open', 'mega-nav-close']);

const props = defineProps({
  menus: {
    type: Object,
  },
  logoPosition: {
    type: String,
  },
});

const SCROLL_MAX_OFFSET = 350;
const MAX_COLUMNS_WITH_FEATURED_ITEM = 2;

const selectedMenu = ref(null);
const isLinkHovered = ref(false);
const menusRefs = ref([]);
const megaNavContainerRef = ref(null);
const lastFocusableMegaNavItem = ref(null);
let closeTimeout = null;
let openTimeout = null;

const { y: scrollOffsetY } = useScroll(isServer() ? null : window);
const isMenuSelected = computed(() => selectedMenu.value != null);
const navItemClasses = computed(() => ({
  'font-s': brandName === 'ChefBrewer',
  'desktop-menu__nav-item--no-underline': brandName === 'Belhaven',
}));

const computedMenus = computed(() => {
  return props.menus?.map((menu) => {
    const isFeaturedItem = menu.fields?.featuredItemEnabled?.value;

    return {
      ...menu,
      classes: getNavContainerClasses(menu, isFeaturedItem),
      navItemsType: getNavItemsType(menu),
      numOfColumns: getNumOfColumns(menu, isFeaturedItem),
      isFeaturedItem,
    };
  });
});

const getNavContainerClasses = (menu, isFeaturedItem) => {
  const items = menu?.fields?.columns?.length || 0;

  return {
    'desktop-menu__container--1-item-featured-item': items === 1 && isFeaturedItem,
    'desktop-menu__container--2-items-featured-item': items > 1 && isFeaturedItem,
    'desktop-menu__container--4-items': items === 4 && !isFeaturedItem,
  };
};

const getNavItemsType = (menu) => {
  const showLinkImages = menu?.fields?.showLinkImages?.value || false;
  if (!showLinkImages) return 'text-only';

  const maxItemsInColumn =
    menu?.fields?.columns.reduce((acc, item) => {
      const itemsNumber = item.fields?.subItems?.length || 0;
      return itemsNumber > acc ? itemsNumber : acc;
    }, 0) || 0;

  return maxItemsInColumn > 2 ? 'image-left' : 'image-top';
};

const getNumOfColumns = (menu, isFeaturedItem) => {
  if (!menu?.fields?.columns) return 0;

  return isFeaturedItem
    ? Math.min(menu.fields.columns.length, MAX_COLUMNS_WITH_FEATURED_ITEM)
    : menu.fields.columns.length;
};

const onLastFocusableItemTab = (index) => {
  if (index.target === lastFocusableMegaNavItem.value) {
    menusRefs.value[selectedMenu.value].focus();
    closeMegaNav();
  }
};

const closeMegaNav = () => {
  selectedMenu.value = null;
  emit('mega-nav-close');
};

const openMegaNav = (index) => {
  if (closeTimeout) clearTimeout(closeTimeout);
  selectedMenu.value = index;
  emit('mega-nav-open');
};

const closeMegaNavWithTimeout = () => {
  closeTimeout = setTimeout(closeMegaNav, 500);
};

const toggleMegaNav = (index, event) => {
  if (event?.type === 'mouseover' || !isMenuSelected.value || selectedMenu.value !== index) {
    openTimeout = setTimeout(() => openMegaNav(index), 1);
  } else {
    if (openTimeout) clearTimeout(openTimeout);
    closeMegaNav();
  }
};

watchEffect(() => {
  if (isMenuSelected.value && scrollOffsetY.value > SCROLL_MAX_OFFSET) closeMegaNav();
});

watchEffect(() => {
  appStore.setBackdrop({ enable: isMenuSelected.value, global: false });
});

watch(selectedMenu, () => {
  if (selectedMenu.value === null || !megaNavContainerRef.value?.length) {
    lastFocusableMegaNavItem.value = null;
    return;
  }

  const activeContainer = Array.from(megaNavContainerRef.value).find(
    (container) => parseInt(container.dataset.index) === selectedMenu.value
  );

  if (!activeContainer) {
    lastFocusableMegaNavItem.value = null;
    return;
  }

  const focusableItems = activeContainer.querySelectorAll(
    'a, button, [tabindex]:not([tabindex="-1"])'
  );

  lastFocusableMegaNavItem.value = focusableItems[focusableItems.length - 1] || null;
});

onBeforeUnmount(() => {
  if (closeTimeout) {
    clearTimeout(closeTimeout);
  }
  appStore.setBackdrop({ enable: false, global: false });
});

defineExpose({ closeMegaNav });
</script>

<style lang="scss">
@import './scss/DesktopMenu.scss';
</style>
