import { ref, inject, computed } from 'vue';
import { defineStore } from 'pinia';

export const useContentCardsStore = defineStore('content-cards', () => {
  const contentCards = ref(null);
  const feedLastUpdated = ref(null);
  const isStoreSetupFailed = ref(false);
  let braze = null;

  const jssStore = inject('jssStore');
  const sitecoreContext = jssStore.sitecoreContext() || {};
  const brandName = sitecoreContext.brandName;
  const contentCardsEnabled = sitecoreContext.enableContentCard;
  const siteVirtualFolder = sitecoreContext.siteVirtualFolder ?? '/';
  const venueId = sitecoreContext.venueId;
  const brazeSdkKey = sitecoreContext.brazeSdkKey;

  const getHeroCarouselContentCards = () => {
    const pageTemplateName = jssStore?.routeData()?.templateName;

    const cards = contentCards.value?.filter(
      (card) =>
        card.extras?.['cc_component'] === 'HeroCarousel' &&
        card.extras?.['cc_page_template'] === pageTemplateName
    );

    if (!cards?.length) return;

    const controlCards = cards.filter((card) => card.extras?.['cc_control'] === 'yes');

    if (controlCards?.length) {
      logControlCardsDisplay(controlCards);
    }

    return parseHeroCarouselCard(
      cards.filter((card) => card.extras?.['cc_control'] === 'no'),
      siteVirtualFolder
    );
  };

  const contentCardsBlockCards = computed(() =>
    contentCards.value
      ?.filter(
        (card) =>
          card.extras?.['cc_component'] === 'ContentCardsBlock' &&
          !isNaN(parseInt(card.extras?.['cc_order']))
      )
      .sort((a, b) =>
        a.extras['cc_order'].localeCompare(b.extras['cc_order'], undefined, { numeric: true })
      )
  );

  const getContentCardsBlockCards = (controlCards = false) => {
    const pageTemplateName = jssStore?.routeData()?.templateName;

    const cards = contentCardsBlockCards.value?.filter(
      (card) =>
        card.extras?.['cc_control'] === (controlCards ? 'yes' : 'no') &&
        card.extras?.['cc_page_template'] === pageTemplateName
    );

    return controlCards ? cards : parseContentCardsBlockCard(cards, siteVirtualFolder);
  };

  const logControlCardsDisplay = (cards) => {
    braze?.logContentCardImpressions(cards);
  };

  const logContentCardDisplay = (cardId) => {
    braze?.logContentCardImpressions(contentCards.value.filter((card) => card.id === cardId));
  };

  const logContentCardClick = (cardId) => {
    braze?.logContentCardClick(contentCards.value.find((card) => card.id === cardId));
  };

  const onContentCardsUpdate = ({ cards, lastUpdated }) => {
    if (feedLastUpdated.value === lastUpdated.getTime()) return;

    const validContentCards = cards.filter(
      (card) => card.extras['cc_brand'] === brandName && card.extras['cc_venueid'] === venueId
    );

    feedLastUpdated.value = lastUpdated.getTime();
    contentCards.value = validContentCards;
  };

  const updateContentCardFeed = () => {
    braze.requestContentCardsRefresh();

    setTimeout(() => updateContentCardFeed(), 60000);
  };

  const setupSDK = () => {
    braze.initialize(brazeSdkKey, {
      baseUrl: 'sdk.fra-02.braze.eu',
      doNotLoadFontAwesome: true,
    });
    braze.subscribeToContentCardsUpdates(onContentCardsUpdate);
    braze.openSession();

    isStoreSetupFailed.value = false;

    updateContentCardFeed();
  };

  const initializeStore = async () => {
    if (braze) return;
    if (!contentCardsEnabled || !brazeSdkKey) {
      isStoreSetupFailed.value = true;
      return;
    }

    try {
      braze = await import(
        /* webpackChunkName: "braze-sdk" */
        /* webpackPrefetch: true */
        /* webpackExports: ["initialize", "openSession", "subscribeToContentCardsUpdates", 
          "requestContentCardsRefresh", "logContentCardImpressions", "logContentCardClick", 
          "logCustomEvent", "isInitialized"] */
        '@braze/web-sdk'
      );
    } catch (error) {
      console.error('Import of Braze module failed');
      isStoreSetupFailed.value = true;
      return;
    }

    window.braze = { logCustomEvent: braze.logCustomEvent, isInitialized: braze.isInitialized };

    if (!window?.Cookiebot?.consent?.marketing) {
      window.addEventListener('CookiebotOnAccept', setupSDK);
      isStoreSetupFailed.value = true;
      return;
    }

    setupSDK();
  };

  return {
    contentCards,
    contentCardsBlockCards,
    feedLastUpdated,
    isStoreSetupFailed,
    initializeStore,
    getHeroCarouselContentCards,
    getContentCardsBlockCards,
    logContentCardDisplay,
    logControlCardsDisplay,
    logContentCardClick,
  };
});

const parseHeroCarouselCard = (cards, baseUrl) =>
  cards?.map((card) => ({
    componentName: 'HeroCarouselItem',
    contentCard: true,
    fields: {
      contentCardId: card.id,
      subtitle: { value: card?.description },
      title: { value: card?.title },
      imageDesktop: { value: { src: card?.imageUrl, alt: card.extras?.['cc_img_alt_text'] ?? '' } },
      showTransparentLayer: { value: true },
      link: {
        value: {
          href: card?.url && (baseUrl + card.url).replace('//', '/'),
          text: card?.linkText,
          target: '_self',
          linktype: 'internal',
        },
      },
    },
  }));

const parseContentCardsBlockCard = (cards, baseUrl) =>
  cards?.map((card) => ({
    id: card.id,
    title: card.title,
    description: card.description,
    image: { src: card.imageUrl, alt: card.extras?.['cc_img_alt_text'] ?? '' },
    link: {
      href: card.url && (baseUrl + card.url).replace('//', '/'),
      text: card.linkText,
      target: '_self',
      linktype: 'internal',
    },
  }));
