import { useI18n } from 'vue-i18n';
import { noDecimalsFilter, thousandsSeparatorFilter } from '../utils/filters';

export const usePubDetailsItemUtils = () => {
  const { t } = useI18n();

  const POUND_SIGN = '£';
  const PERCENTAGE_SIGN = '%';
  const FALLBACK_VALUE = t('important-information-default-value');

  const transformNum = (value) => thousandsSeparatorFilter(noDecimalsFilter(value));

  const transformPercentage = (value) => value + PERCENTAGE_SIGN;

  const transformString = (fieldText) => {
    if (typeof fieldText == 'string' && fieldText.includes('\n')) {
      return fieldText.split('\n');
    }
    return fieldText;
  };

  const createContentItem = (
    key,
    val,
    transformNumber = true,
    isBolded = false,
    isPercentage = false
  ) => {
    let value = FALLBACK_VALUE;
    let transformedValue = val;

    if (transformNumber && typeof val === 'number' && !Number.isNaN(val)) {
      transformedValue = POUND_SIGN + transformNum(val);
    } else if (isPercentage && val) {
      transformedValue = transformPercentage(val);
    } else {
      transformedValue = transformString(transformedValue);
    }

    if (
      transformedValue != null &&
      transformedValue !== '' &&
      (!Array.isArray(transformedValue) || transformedValue.length)
    ) {
      value = transformedValue;
    }

    return {
      label: t(`${key}`),
      value,
      isBolded,
    };
  };

  return { createContentItem, transformNum, transformPercentage, transformString, FALLBACK_VALUE };
};
