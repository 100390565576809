<template>
  <section class="featured-item">
    <div class="featured-item__img">
      <base-image
        class="featured-item__pic"
        :desktop-url="fields.featuredItemCoverImage.value.src"
        :desktop-alt="fields.featuredItemCoverImage.value.alt"
        :custom-params="{
          fullWidthDesktop: 610,
          desktop: numOfColumns === 1 ? 840 : 610,
          tablet: 960,
          mobile: 715,
          mobileS: 360,
        }"
        :fallback-param="{
          fullWidthDesktop: 's',
          desktop: numOfColumns === 1 ? 'w' : 's',
        }"
        full-width
        loading="lazy"
        fetchpriority="low"
      />
    </div>
    <div class="featured-item__content">
      <sc-text
        tag="p"
        class="featured-item__heading"
        :class="headingClasses"
        :field="fields.featuredItemHeading"
      />
      <sc-text tag="p" class="featured-item__description" :field="fields.featuredItemDescription" />
      <base-button
        variant="secondary"
        type="link"
        class="featured-item__cta"
        :link="fields.featuredItemCta.value"
        @click="handleNavigationItemClick(fields.featuredItemCta.value.text, 'main_header_promo')"
      />
    </div>
  </section>
</template>

<script setup>
import { inject, computed } from 'vue';
import { Text as ScText } from '@sitecore-jss/sitecore-jss-vue';
import BaseImage from '../../2_elements/BaseImage/BaseImage.vue';
import BaseButton from '../../2_elements/BaseButton/BaseButton.vue';

const { handleNavigationItemClick } = inject('dataLayerFunctions');

const jssStore = inject('jssStore');
const brandName = jssStore?.sitecoreContext()?.brandName;

defineProps({
  fields: {
    type: Object,
  },
  numOfColumns: {
    type: Number,
    default: 1,
  },
});

const headingClasses = computed(() => [
  ['Seared', 'Kinsmith', 'HighTales'].includes(brandName) ? 'font-s' : 'font-m',
]);
</script>
<style lang="scss">
@import './scss/FeaturedItem.scss';
</style>
