import { reactive, ref, computed } from 'vue';
import { defineStore } from 'pinia';
import { isServer, isEditorActive } from '@sitecore-jss/sitecore-jss';

export const useAppStore = defineStore('app', () => {
  const bodyOffset = ref(0);
  const isMapApiLoaded = ref(false);
  const isLocalitiesApiLoaded = ref(false);
  const formSuccessState = ref({});
  const isSmoothScrollDisabled = ref(false);
  const backdrop = reactive({ isEnabled: false, isGlobal: false });
  const scrollPosition = ref(null);
  const guestlineActiveUrl = ref(null);
  const scrollBlockCount = ref(0);

  const isScrollOnBodyDisabled = computed(() => scrollBlockCount.value > 0);

  const setGuestlineActiveUrl = (url) => {
    guestlineActiveUrl.value = url ? url + '&enableCustomFrameAncestor=true' : null;
  };

  const updateFormSuccessState = (id, val) => {
    formSuccessState.value[id] = val;
  };

  const setBodyOffset = (newVal) => {
    if (isServer() || bodyOffset.value === newVal) return;
    bodyOffset.value = newVal;
    document.body.style.setProperty('--body-offset', newVal ? `${newVal}px` : 'unset');
  };

  const setMapApiLoaded = () => {
    isMapApiLoaded.value = true;
  };

  const setLocalitiesApiLoaded = () => {
    isLocalitiesApiLoaded.value = true;
  };

  const toggleBodyClass = (className, enable) => {
    document.body.classList.toggle(className, enable);
  };

  const disableScrollOnBody = (newVal) => {
    if (isServer() || isEditorActive()) return;
    scrollBlockCount.value = Math.max(scrollBlockCount.value + (newVal ? 1 : -1), 0);
    toggleBodyClass('disable-body-scroll', !!scrollBlockCount.value);
  };

  const disableSmoothScroll = (newVal) => {
    if (isServer() || isSmoothScrollDisabled.value === newVal) return;
    isSmoothScrollDisabled.value = newVal;
    toggleBodyClass('disable-smooth-scroll', newVal);
  };

  const setBackdrop = ({ enable, global }) => {
    if (isServer()) return;
    backdrop.isEnabled = enable;
    backdrop.isGlobal = global;
    toggleBodyClass('backdrop', enable);
    toggleBodyClass('backdrop--global', enable && global);
  };

  const blockScrolling = () => {
    if (isServer() || isEditorActive()) return;
    scrollPosition.value = window.scrollY;
    disableScrollOnBody(true);
    document.body.style.top = `-${scrollPosition.value}px`;
  };

  const unblockScrolling = () => {
    if (isServer() || isEditorActive()) return;
    disableScrollOnBody(false);
    document.body.style.removeProperty('top');
    window.scrollTo({ top: scrollPosition.value, left: 0, behavior: 'instant' });
  };

  const updateScrollingPosition = (newPosition) => {
    scrollPosition.value = newPosition;
    unblockScrolling();
    blockScrolling();
  };

  return {
    bodyOffset,
    isMapApiLoaded,
    isLocalitiesApiLoaded,
    formSuccessState,
    isScrollOnBodyDisabled,
    isSmoothScrollDisabled,
    backdrop,
    scrollPosition,
    guestlineActiveUrl,
    setBodyOffset,
    setMapApiLoaded,
    setLocalitiesApiLoaded,
    updateFormSuccessState,
    disableScrollOnBody,
    disableSmoothScroll,
    setBackdrop,
    blockScrolling,
    unblockScrolling,
    updateScrollingPosition,
    setGuestlineActiveUrl,
  };
});
