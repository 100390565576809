<template>
  <component
    :is="getLinkTag(props?.link?.value?.linktype)"
    class="nav-item no-underline"
    :class="classes"
    v-bind="getLinkBindings(link)"
    @click="onClick"
  >
    <div v-if="itemType !== 'text-only'" class="nav-item__image-wrapper" aria-hidden="true">
      <base-image
        :desktop-url="image?.value?.src || navLinkImagePlaceholder?.src"
        :desktop-alt="image?.value?.alt || navLinkImagePlaceholder?.alt"
        :custom-params="customParams"
        loading="lazy"
        fallback-param="nav"
        fetchpriority="low"
      />
    </div>
    <div class="nav-item__content">
      <sc-text class="nav-item__title" tabindex="-1" :field="title" />
      <sc-text v-if="description?.value" class="nav-item__description" :field="description" />
    </div>
  </component>
</template>

<script setup>
import { computed, inject } from 'vue';
import { Text as ScText } from '@sitecore-jss/sitecore-jss-vue';
import { getLinkBindings, getLinkTag } from '../../../utils/linkTransformer';
import BaseImage from '../../2_elements/BaseImage/BaseImage.vue';

const { handleNavigationItemClick } = inject('dataLayerFunctions');
const navLinkImagePlaceholder = inject('navLinkImagePlaceholder');

const props = defineProps({
  image: {
    type: Object,
  },
  title: {
    type: Object,
  },
  description: {
    type: Object,
  },
  link: {
    type: Object,
  },
  itemType: {
    type: String,
    validator: (value) => ['text-only', 'image-left', 'image-top'].includes(value),
    default: 'text-only',
  },
});

const classes = computed(() => ({
  'nav-item--image-left': props.itemType === 'image-left',
  'nav-item--image-top': props.itemType === 'image-top',
}));

const customParams = computed(() => {
  if (props.itemType === 'text-only') return null;
  if (props.itemType === 'image-left') return { mobileS: 80, mobile: 80, tablet: 80, desktop: 80 };
  return { mobileS: 400, mobile: 400, tablet: 400, desktop: 400 };
});

const onClick = () => {
  handleNavigationItemClick(props.title?.value, 'main_header_second_level');
};
</script>

<style lang="scss">
@import './scss/NavItem.scss';
</style>
