<template>
  <div
    v-if="isOpen"
    v-on-click-outside="onClose"
    class="popover-modal"
    :class="[`popover-modal--${position}`]"
    tabindex="-1"
    @keydown.esc="onClose"
  >
    <slot></slot>
  </div>
</template>

<script setup>
import { vOnClickOutside } from '@vueuse/components';

defineProps({
  isOpen: {
    type: Boolean,
    required: true,
  },
  position: {
    type: String,
    default: 'right',
    validator: (value) => ['left', 'right'].includes(value),
  },
});

const emit = defineEmits(['close']);

const onClose = () => {
  emit('close');
};
</script>

<style lang="scss">
@import './scss/PopoverModal.scss';
</style>
