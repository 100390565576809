<template>
  <section class="pub-info-bar show-on-load">
    <div class="pub-info-bar__container container">
      <div
        class="pub-info-bar__text-container"
        :class="{
          ...detectComponentVariant(variantedComponents.PUB_INFO_BAR),
        }"
      >
        <button
          class="pub-info-bar__pub-info"
          :class="{
            'pub-info-bar__pub-info--open': isNavigationModalOpen,
          }"
          :aria-expanded="isNavigationModalOpen"
          :aria-label="`${props.fields.pubInfo?.value} - click to change location`"
          @click="toggleNavigationModal"
          @keydown.tab.shift.exact="closeNavigationModal"
          @keydown.esc="closeNavigationModal"
        >
          <i class="icon icon-Location" aria-hidden="true"></i>
          <span class="pub-info-bar__pub-info-link">{{ fields.pubInfo?.value }}</span>
        </button>
        <div class="pub-info-bar__opening-hours">
          {{ openingHours }}
        </div>
      </div>

      <PopoverModal
        :is-open="isNavigationModalOpen"
        show-close-button
        position="left"
        @close="toggleNavigationModal"
      >
        <div class="pub-info-bar__navigation-modal-content">
          <ul class="pub-info-bar__navigation-modal-content-list">
            <li v-if="fields.homepageLink?.value?.href">
              <router-link
                :to="fields.homepageLink.value.href"
                @click="
                  onNavigationLinkClick(
                    fields.homepageLink.value.href,
                    t('change-location-homepage')
                  )
                "
              >
                {{ t('change-location-homepage') }}
              </router-link>
            </li>
            <li v-if="googleMapsUrl">
              <a
                :href="googleMapsUrl"
                target="_blank"
                rel="noopener noreferrer"
                @click="onNavigationLinkClick(googleMapsUrl, t('change-location-get-directions'))"
              >
                {{ t('change-location-get-directions') }}
              </a>
            </li>
            <li v-if="fields.venueFinderLink?.value?.href">
              <a
                :href="fields.venueFinderLink.value.href"
                @click="
                  onNavigationLinkClick(
                    fields.venueFinderLink.value.href,
                    t('change-location-venue-finder')
                  )
                "
                @keydown.tab.exact="closeNavigationModal"
              >
                {{ t('change-location-venue-finder') }}
              </a>
            </li>
          </ul>
        </div>
      </PopoverModal>

      <button
        v-if="fields.isSpecialHours?.value"
        class="pub-info-bar__occasion-info-toggle"
        :class="{
          'pub-info-bar__occasion-info-toggle--open': isOccasionInfoOpen,
          ...detectComponentVariant(variantedComponents.PUB_INFO_BAR),
        }"
        :aria-label="'Toggle ' + t('pub-info-bar-tooltip-link-label')"
        :aria-expanded="isOccasionInfoOpen"
        @click="toggleOccasionInfo"
        @keydown.tab.shift.exact="closeOccasionInfo"
        @keydown.esc="closeOccasionInfo"
      >
        <i class="icon icon-Info" aria-hidden="true"></i>
      </button>

      <PopoverModal :is-open="isOccasionInfoOpen" position="right" @close="toggleOccasionInfo">
        <div class="pub-info-bar__occasion-info-content">
          <template v-if="fields.occasionName?.value">
            {{ fields.occasionName?.value }} {{ t('pub-info-bar-tooltip') }}
          </template>
          <template v-else>
            {{ t('pub-info-bar-tooltip-no-occasion-name') }}
          </template>

          <router-link
            v-if="fields.toolTipLink?.value?.href"
            :to="fields.toolTipLink.value.href"
            @keydown.tab.exact="closeOccasionInfo"
          >
            {{ t('pub-info-bar-tooltip-link-label') }}
          </router-link>
        </div>
      </PopoverModal>
    </div>
  </section>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useDebounceFn } from '@vueuse/core';
import {
  useDetectVariantByBrandAndTheme,
  variantedComponents,
} from '../../utils/componentVariants';
import PopoverModal from './PopoverModal/PopoverModal.vue';
import { useRegisterDataLayerAction } from '../../data-layer/helpers/registerDataLayer';
import { DEFAULT_DATA_LAYER } from '../../data-layer/helpers/defaultProps';

const detectComponentVariant = useDetectVariantByBrandAndTheme();

const registerDataLayerAction = useRegisterDataLayerAction(DEFAULT_DATA_LAYER);

const { t } = useI18n();

const props = defineProps({
  fields: {
    type: Object,
    default: () => ({}),
  },
  rendering: {
    type: Object,
  },
  params: {
    type: Object,
  },
});

const isOccasionInfoOpen = ref(false);
const isNavigationModalOpen = ref(false);

const googleMapsUrl = computed(() => {
  const latitude = props.fields.coordinates?.value?.['Coordinate.Latitude'];
  const longitude = props.fields.coordinates?.value?.['Coordinate.Longitude'];
  if (!latitude || !longitude) return null;
  return `https://www.google.com/maps?q=${latitude},${longitude}`;
});

const openingHours = computed(() =>
  props.fields.openingHours?.value
    ? `${t('pub-info-bar-open')} ${props.fields.openingHours.value}`
    : t('pub-info-bar-closed')
);

const toggleOccasionInfo = useDebounceFn(() => {
  isOccasionInfoOpen.value = !isOccasionInfoOpen.value;
}, 10);

const toggleNavigationModal = useDebounceFn(() => {
  isNavigationModalOpen.value = !isNavigationModalOpen.value;
}, 10);

const closeOccasionInfo = () => {
  isOccasionInfoOpen.value = false;
};

const closeNavigationModal = () => {
  isNavigationModalOpen.value = false;
};

const onNavigationLinkClick = (link, text) => {
  registerDataLayerAction({
    event_name: 'click_link',
    component: 'venue_info_bar',
    link_page_path: link,
    _link_text: text,
  });
  closeNavigationModal();
};
</script>

<style lang="scss">
@import './scss/PubInfoBar.scss';
</style>
