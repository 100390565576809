<template>
  <router-view />
</template>

<script setup>
import { onMounted } from 'vue';
import { useContentCardsStore } from './stores/contentCardsStore';

const contentCardsStore = useContentCardsStore();

onMounted(async () => {
  await contentCardsStore.initializeStore();
});
</script>
